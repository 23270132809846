import gql from 'graphql-tag';

export const FRAGMENT_USER = gql`
  fragment userFields on User {
    id
    email
    mobileNumber
    nickname
    description
    birth
    gender
    privateProfile
    joinedAt
    certificated
    verified
    socialLinks {
      id
      socialUserId
      provider
    }
    updatedAt
    certificated
    profileImage {
      id
      uri
    }
    tags {
      id
      tagTemplateId
      name
    }
  }
`;

export const UPDATE_MUTAION = gql`
  mutation Update($param: UserUpdateParam!, $userId: ID) {
    updateUser(param: $param, userId: $userId) {
      ...userFields
    }
  }

  ${FRAGMENT_USER}
`;

export const UNVERIFY_USER_MUTATION = gql`
  mutation UnverifyUser($userId: ID!) {
    unverifyUser(userId: $userId) {
      id
    }
  }
`;

export const VERIFY_USER_MUTATION = gql`
  mutation verifyUser($userId: ID!) {
    verifyUser(userId: $userId) {
      id
    }
  }
`;

export const CHECK_USER_EMAIL = gql`
  mutation checkUserEmail($email: String!) {
    checkUserEmail(email: $email)
  }
`;

export const CHANGE_USER_FEED_PRIVATE_MUTAION = gql`
  mutation ChangeUserFeedPrivate($isPrivate: Boolean!) {
    changeProfilePrivacy(privateProfile: $isPrivate) {
      message
      success
    }
  }
`;

export const WITHDRAW_MUTATION = gql`
  mutation Withdraw($userId: ID!) {
    withdraw(userId: $userId)
  }
`;

export const GET_ONE_QUERY = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      referee {
        id
        email
      }
      noticeReceiveAgreements {
        id
        channel
        advertised
        agreed
        agreedAt
        updatedAt
      }
      ...userFields
      usablePoint {
        total
        availables
      }
      referee {
        id
      }
    }
  }

  ${FRAGMENT_USER}
`;
export const GET_USER_POINTS_QUERY = gql`
  query GetPointsOfUser($id: ID!, $page: Int, $size: Int) {
    user(id: $id) {
      id
      points(page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            kind
            type {
              id
              name
              label
              code
              onlyAdmin
              status
              # worker {
              #   id
              #   name
              # }
            }
            amount
            usedAmount
            refType
            refId
            user {
              id
              nickname
            }
            title
            description
            createdAt
            updatedAt
            appliedAt
            expiredAt
            accountingType
            worker {
              id
              nickname
            }
            cancelled
            deleted
            deletedAt
            usedHistories {
              amount
              used {
                id
                kind
                type {
                  id
                  name
                  label
                  code
                  onlyAdmin
                  status
                }
                amount
                usedAmount
                refType
                refId
                title
                description
                appliedAt
                expiredAt
                accountingType
              }
            }
            savedLinks {
              amount
              saved {
                id
                kind
                type {
                  id
                  name
                  label
                  code
                  onlyAdmin
                  status
                }
                amount
                usedAmount
                refType
                refId
                title
                description
                appliedAt
                expiredAt
                accountingType
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SOCIALLINKS_QUERY = gql`
  query GetSocialLinks($userId: ID!, $filter: SocialLinkFilter) {
    user(id: $userId) {
      socialLinks(filter: $filter) {
        id
        socialUserId
        provider
      }
    }
  }
`;

export const GET_ONE_BY_SOCIAL_QUERY = gql`
  query GetUserBySocialId(
    $socialUserId: String!
    $provider: SocialLinkProviderType!
  ) {
    userBySocialUserId(socialUserId: $socialUserId, provider: $provider) {
      ...userFields
    }
  }

  ${FRAGMENT_USER}
`;

export const DEACTIVATE_SOCIAL_LINK = gql`
  mutation DeactivateSocialLink(
    $userId: ID!
    $provider: SocialLinkProviderType!
  ) {
    deactivateSocialLink(userId: $userId, provider: $provider)
  }
`;

export const SET_NEW_PASSWORD = gql`
  mutation SetNewPassword($email: String!, $newPassword: String!) {
    setNewPassword(email: $email, newPassword: $newPassword)
  }
`;

export const GET_USER_LIST_BY_FILTER_QUERY = gql`
  query getUserListByFilter($page: Int, $size: Int, $filter: UserFilter) {
    users(page: $page, size: $size, filter: $filter) {
      totalCount
      edges {
        node {
          ...userFields
          profileImage {
            id
            uri
          }
        }
      }
    }
  }

  ${FRAGMENT_USER}
`;

export const GET_MYINFO_QUERY = gql`
  query GetMyInfo {
    me {
      id
      user {
        ...userFields
      }
    }
  }

  ${FRAGMENT_USER}
`;

export const GET_PROFILE_QUERY = gql`
  query GetProfile($id: ID!) {
    profile(id: $id) {
      id
      nickname
    }
  }
`;

export const CHANGE_NOTICE_RECEIVE_AGREEMENT_MUTATION = gql`
  mutation ChangeNoticeReceiveAgreement(
    $noticeReceiveParams: [NoticeReceiveParam!]!
  ) {
    changeNoticeReceiveAgreement(noticeReceiveParams: $noticeReceiveParams) {
      message
      success
    }
  }
`;

export const CHANGE_USER_INVITATION_CODE_MUTATION = gql`
  mutation ChangeUserInvitationCode($userId: ID!) {
    changeUserInvitationCode(userId: $userId) {
      code
      userId
    }
  }
`;

export const CHANGE_MOBILE_NUMBER = gql`
  mutation ChangeMobileNumber($mobileNumber: String!, $userId: ID!) {
    changeMobileNumber(mobileNumber: $mobileNumber, userId: $userId) {
      ...userFields
    }
  }

  ${FRAGMENT_USER}
`;

export const CHANGE_USER_EMAIL = gql`
  mutation ChangeUserEmail($email: String!, $userId: ID!) {
    changeUserEmail(email: $email, userId: $userId) {
      ...userFields
    }
  }

  ${FRAGMENT_USER}
`;

export const INACTIVATE_USER_MUTATION = gql`
  mutation InactivateUser($userId: ID!) {
    inactivateUser(userId: $userId) {
      ...userFields
    }
  }

  ${FRAGMENT_USER}
`;
export const ACTIVATE_USER_MUTATION = gql`
  mutation ActivateUser($userId: ID!) {
    activateUser(userId: $userId) {
      ...userFields
    }
  }

  ${FRAGMENT_USER}
`;

export const REGIST_USER_REFEREE_MUTATION = gql`
  mutation registUserReferee($param: RefereeRegisterParam!) {
    registerUserReferee(param: $param) {
      userId
      refereeId
    }
  }
`;

export const CERTIFICATE_USER = gql`
  mutation certificateUser($userId: ID!) {
    certificateUser(userId: $userId) {
      id
    }
  }
`;

export const DECERTIFICATE_USER = gql`
  mutation decertificateUser($userId: ID!) {
    decertificateUser(userId: $userId) {
      id
    }
  }
`;

export const GET_MY_PROFILE = gql`
  query getMyProfile {
    me {
      id
      user {
        id
        nickname
        mobileNumber
        email
      }
    }
  }
`;

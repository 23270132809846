








































































































































































































































import InlineInput from '@/components/Forms/InlineInput.vue';
import InlineRadioInput from '@/components/Forms/InlineRadioInput.vue';
import InlineText from '@/components/Forms/InlineText.vue';
import Vue from 'vue';
import {
  CERTIFICATE_USER,
  CHANGE_MOBILE_NUMBER,
  CHANGE_USER_EMAIL,
  CHECK_USER_EMAIL,
  DEACTIVATE_SOCIAL_LINK,
  DECERTIFICATE_USER,
  GET_ONE_QUERY,
  SET_NEW_PASSWORD,
  UNVERIFY_USER_MUTATION,
  UPDATE_MUTAION,
  VERIFY_USER_MUTATION,
  WITHDRAW_MUTATION,
} from '../graphqls/user';
import Instant from '@/components/Labels/Instant.vue';
import { Pagination, Select, Table, TableColumn } from 'element-ui';
import { SocialLink as SocialLinkType, User } from '../models/user';
import SocialLink from '@/components/SocialLink.vue';
import InputRow from '@/components/Forms/InputRow.vue';
import UserPointHistoryList from './UserPointHistoryList.vue';
import { debounce } from 'lodash';

export default Vue.extend({
  components: {
    InlineInput,
    InlineRadioInput,
    InlineText,
    Instant,
    SocialLink,
    InputRow,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    UserPointHistoryList,
    [Select.name]: Select,
  },
  data(): {
    user: User;
    originUser: User | null;
    isEmailLocked: boolean;
    isEmailValid: boolean;
    previousEmail: string;
    debounceCheckEmail: any;
  } {
    return {
      user: {
        birth: '',
        socialLinks: [],
        id: '',
        email: '',
        mobileNumber: '',
        description: '',
        gender: '',
        nickname: '',
        noticeReceiveAgreements: [],
        privateProfile: false,
        certificated: false,
        verified: false,
        usablePoint: {
          total: 0,
        },
        tags: [],
      },
      originUser: null,
      isEmailLocked: true,
      isEmailValid: true,
      previousEmail: '', // 이전 이메일 값 추적용
      debounceCheckEmail: null, // 초기값 설정
    };
  },
  computed: {
    converted: {
      get(): string {
        let result = '';
        if (!this.user.birth) {
          return result;
        }
        result += this.user.birth.substring(0, 4);
        result += '-';
        result += this.user.birth.substring(4, 6);
        result += '-';
        result += this.user.birth.substring(6, 8);
        return result;
      },
      set(yyyyMMdd: string): void {
        let result = '';
        if (yyyyMMdd) {
          result += yyyyMMdd.substring(0, 4);
          result += yyyyMMdd.substring(5, 7);
          result += yyyyMMdd.substring(8, 10);
          this.user.birth = result;
        }
      },
    },
  },
  created() {
    this.previousEmail = this.user.email; // 초기값 설정
    // input 이벤트에 debounce 적용
    this.debounceCheckEmail = debounce(() => {
      if (this.user.email !== this.previousEmail && this.user.email) {
        this.checkEmailDuplicate();
        this.previousEmail = this?.user.email; // 업데이트
      }
    }, 300);
  },
  apollo: {
    user: {
      query: GET_ONE_QUERY,
      variables(): { id: string } {
        return {
          id: this.$route.params.id,
        };
      },
      update(data): User {
        this.originUser =
          this.originUser || JSON.parse(JSON.stringify(data.user));
        console.log('data.user :', data.user);
        return data.user;
      },
    },
  },
  methods: {
    changePassword() {
      const generateRandomPassword = () => {
        const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
        let password = '';
        for (let i = 0; i < 10; i++) {
          password += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return password + '!!';
      };

      const setNewPassword = async () => {
        try {
          // 이메일이 존재하지 않거나 중복인 경우
          if (!this.isEmailValid || !this.user.email) {
            throw new Error('이메일을 확인해 주세요..');
          }

          const newPassword = generateRandomPassword();

          await this.$apollo.mutate({
            mutation: SET_NEW_PASSWORD,
            variables: {
              email: this.user.email,
              newPassword: newPassword,
            },
          });

          // 성공 시 새로운 비밀번호 모달 표시
          this.$modal.show({
            title: '비밀번호 초기화 완료',
            type: 'success',
            message: `초기화된 비밀번호: ${newPassword}`,
          });
        } catch (err) {
          this.$notify({
            title: '비밀번호 초기화 실패',
            type: 'warning',
            message: (err as Error).message,
          });
        }
      };

      // 확인 모달 표시
      this.$modal.show(
        {
          title: '비밀번호 초기화',
          type: 'warning',
          message: `임시 비밀번호로 초기화 합니다. 변경 후 복구할 수 없습니다. 진행하시겠습니까?`,
          showCancelButton: true,
        },
        setNewPassword
      );
    },
    // 소셜 링크 연결 끊기 모달
    unlinkSocialModal(socialLink: SocialLinkType) {
      const unlinkSocial = async () => {
        try {
          // unlinkSocialLink
          await this.$apollo.mutate({
            mutation: DEACTIVATE_SOCIAL_LINK,
            variables: {
              userId: this.user.id,
              provider: socialLink.provider,
            },
          });

          // 성공 시 새로운 비밀번호 모달 표시
          this.$modal.show({
            title: '연결 끊기 완료',
            type: 'success',
            message: `소셜 가입 연결이 끊겼습니다.\n이메일 로그인으로 전환되었습니다.`,
          });

          // UI 업데이트: 해당 소셜 링크 제거
          this.user.socialLinks = this.user.socialLinks.filter(
            link => link.id !== socialLink.id
          );
        } catch (err) {
          this.$modal.show({
            title: '연결 끊기 실패',
            type: 'warning',
            message: (err as Error).message,
          });
          throw err;
        }
      };
      // 확인 모달 표시
      this.$modal.show(
        {
          title: '이메일 계정 전환',
          type: 'warning',
          message: `이메일 로그인으로 전환됩니다.`,
          showCancelButton: true,
        },
        unlinkSocial
      );
    },
    // blur 이벤트로 API 호출
    async checkEmailOnBlur() {
      if (this.user.email !== this.previousEmail && this.user.email) {
        await this.checkEmailDuplicate();
        this.previousEmail = this?.user.email; // 업데이트
      }
    },
    // 300ms 대기
    deleteModal(user: User) {
      const deleteUser = async () => {
        try {
          const res = await this.$apollo.mutate({
            mutation: WITHDRAW_MUTATION,
            variables: {
              userId: user.id,
            },
          });
          this.$modal.show({
            title: '삭제 성공!',
            type: 'info',
            showCancelButton: true,
            //   message: err.message,          showCancelButton: true,
          });
        } catch (err: unknown) {
          this.$modal.show({
            title: '삭제 실패!',
            type: 'warning',
            message: (err as Error).message,
            showCancelButton: true,
          });

          throw err;
        }
        return user;
      };
      this.$modal.show(
        {
          title: '정말 삭제하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        deleteUser
      );
    },
    changeMobileNumberModal(user: User) {
      const changeMobileNumber = async () => {
        try {
          const res1 = await this.$apollo.mutate({
            mutation: CHANGE_MOBILE_NUMBER,
            variables: {
              mobileNumber: user.mobileNumber,
              userId: user.id,
            },
          });
          this.$modal.show({
            title: '수정 성공!',
            type: 'info',
            //   message: err.message,
            showCancelButton: true,
          });
        } catch (err: unknown) {
          this.$modal.show({
            title: '수정 실패!',
            type: 'warning',
            message: (err as Error).message,
            showCancelButton: true,
          });

          throw err;
        }
        return user;
      };
      this.$modal.show(
        {
          title: '정말 수정하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        changeMobileNumber
      );
    },
    changeUserEmailModal(user: User) {
      const changeUserEmail = async () => {
        try {
          await this.$apollo.mutate({
            mutation: CHANGE_USER_EMAIL,
            variables: {
              email: user.email,
              userId: user.id,
            },
          });
          this.$modal.show({
            title: '수정 성공!',
            type: 'info',
            //   message: err.message,
            showCancelButton: true,
          });
        } catch (err: unknown) {
          this.$modal.show({
            title: '수정 실패!',
            type: 'warning',
            message: (err as Error).message,
            showCancelButton: true,
          });

          throw err;
        }
        return user;
      };
      this.$modal.show(
        {
          title: '정말 수정하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        changeUserEmail
      );
    },
    toggleVerifyPhoneNumber(user: User) {
      const userVerifyMutation = async () => {
        try {
          await this.$apollo.mutate({
            mutation: VERIFY_USER_MUTATION,
            variables: {
              userId: user.id,
            },
          });
        } catch (err) {
          this.$modal.show({
            title: '수정 실패!',
            type: 'warning',
            message: (err as Error).message,
            showCancelButton: true,
          });

          user.verified = !user.verified;
          throw err;
        }
      };

      const userUnverifyMutation = async () => {
        try {
          await this.$apollo.mutate({
            mutation: UNVERIFY_USER_MUTATION,
            variables: {
              userId: user.id,
            },
          });
        } catch (err) {
          this.$modal.show({
            title: '수정 실패!',
            type: 'warning',
            message: (err as Error).message,
            showCancelButton: true,
          });

          user.verified = !user.verified;
          throw err;
        }
      };

      if (user.verified) {
        userVerifyMutation();
      } else {
        this.$modal.show(
          {
            title: '정말 본인인증을 취소하시겠습니까??',
            type: 'warning',
            showCancelButton: true,
          },
          userUnverifyMutation
        );
      }
    },

    // 이메일 중복 체크 API 호출
    async checkEmailDuplicate() {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: CHECK_USER_EMAIL,
          variables: {
            email: this.user.email,
          },
        });
        this.isEmailValid = data.checkUserEmail;
      } catch (err) {
        if (err.message.includes('이미 가입된 이메일')) {
          this.isEmailValid = false;
        } else {
          this.$modal.show({
            title: '이메일 중복 검사 오류',
            type: 'warning',
            message: (err as Error).message,
          });
        }
      }
    },
    updateModal(user: User) {
      const param = {
        email: user.email,
        nickname: user.nickname,
        description: user.description,
        birth: user.birth,
        gender: user.gender,
      };
      const updateUser = async () => {
        try {
          const res = await this.$apollo.mutate({
            mutation: UPDATE_MUTAION,
            variables: {
              param,
              userId: user.id,
            },
          });
          if (this.originUser?.certificated !== user.certificated) {
            if (user.certificated) {
              const res = await this.$apollo.mutate({
                mutation: CERTIFICATE_USER,
                variables: {
                  userId: user.id,
                },
              });
            } else {
              const res = await this.$apollo.mutate({
                mutation: DECERTIFICATE_USER,
                variables: {
                  userId: user.id,
                },
              });
            }
          }
          this.$modal.show(
            {
              title: '수정 성공!',
              type: 'info',
              //   message: err.message,
            },
            () => {
              location.reload();
            }
          );
        } catch (err: unknown) {
          this.$modal.show({
            title: '수정 실패!',
            type: 'warning',
            message: (err as Error).message,
            showCancelButton: true,
          });

          throw err;
        }
        return user;
      };
      this.$modal.show(
        {
          title: '정말 수정하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        updateUser
      );
    },
  },
});
